.Aside-module__link {
  font-size: 10px;
  line-height: 15px;
  font-weight: bold;
  color: #154f9c;
  padding: 8px 12px;
}
.Aside-module__link:hover,
link:focus {
  border: 1px solid #154f9c;
  border-radius: 4px;
}
.Aside-module__link.Aside-module__withBorder {
  border: 1px solid #154f9c;
  border-radius: 4px;
}
.Aside-module__link.Aside-module__withBorder:hover,
.Aside-module__link.Aside-module__withBorder:focus {
  border: 1px solid #154f9c;
  background-color: #154f9c;
  border-radius: 4px;
  color: white;
}

.Aside-module__linksWrapper {
  /* margin-left: auto; */
  display: flex;
  gap: 8px;
}

.Aside-module__userName {
  word-break: break-word;
  font-size: 18px;
  line-height: 24px;
  color: #154f9c;
}

.Aside-module__menuPayments {
  display: flex;
  flex-wrap: wrap;
}

.Aside-module__buildVer {
  font-size: 12px;
  width: 100%;
  margin-top: 20px;
  color: var(--lightGray);
}



/************/

.Aside-module__menuContent {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 300px;
  background-color: #ffffff;
  height: 100%;
  /* transition: 0.5s; */
}

body[dir="rtl"] .Aside-module__menuContent {
  left: -300px;
}

body[dir="ltr"] .Aside-module__menuContent {
  right: -300px;
}

.Aside-module__bgForMenu {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.Aside-module__menuContent > header {
  padding: 15px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  box-shadow: 0 2px 6px 0 rgba(217, 217, 217, 0.5);
  align-items: center;
  position: relative;
  z-index: 2;
}

.Aside-module__menuContent > header > span {
  cursor: pointer;
}

.Aside-module__menuContent > header > a {
  /* background-color: #ff8d00; */
  background-color: #ffffff;
  border-radius: 4px;
  width: 108px;
  height: 31px;
  color: #ffffff;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  line-height: 31px;
  color: #ec6234;
  border: solid 1px #ec6234;
}

.Aside-module__menuContent > .Aside-module__menuBody {
  height: calc(100% - 61px);
  overflow-y: auto;
}



.Aside-module__logInSection,
.Aside-module__loggedInLangSwich {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f4f7fb;
}

.Aside-module__logInSection > a,
.Aside-module__loggedInLangSwich > a {
  color: var(--blueColor);
  font-size: 10px;
  font-weight: bold;
}

body[dir="rtl"] .Aside-module__logInSection > a:first-child {
  margin-left: auto;
}

body[dir="ltr"] .Aside-module__logInSection > a:first-child {
  margin-right: auto;
}

/* body[dir="rtl"] .loggedInLangSwich > a:first-child {
  margin-left: auto;
}

body[dir="ltr"] .loggedInLangSwich > a:first-child {
  margin-right: auto;
} */

.Aside-module__langSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  color: #484848 !important;
}

.Aside-module__langSwitch > span {
  margin: 0 8px;
}

.Aside-module__logInSection > a + a {
  border: solid 1px var(--blueColor);
  border-radius: 4px;
  padding: 10px;
}

.Aside-module__menuSection {
  padding: 20px;
  position: relative;
}

.Aside-module__menuSection > strong.Aside-module__title {
  color: var(--blueColor);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  display: block;
}

.Aside-module__menuSection > ul > li {
  padding: 10px 5px;
  box-sizing: border-box;
}

.Aside-module__menuSection > ul > li:hover,
.Aside-module__menuSection > ul > li.Aside-module__activeMenuLink {
  background-color: #f4f7fb;
  position: relative;
}

.Aside-module__menuSection > ul > li:hover > a > span,
.Aside-module__menuSection > ul > li.Aside-module__activeMenuLink > a > span {
  color: var(--blueColor);
}

.Aside-module__menuSection > ul > li:hover::before,
.Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::before {
  content: "";
  position: absolute;
  background-color: #f4f7fb;
  width: 20px;
  height: 100%;
  top: 0;
}

body[dir="rtl"] .Aside-module__menuSection > ul > li:hover::before,
body[dir="rtl"] .Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::before {
  right: -20px;
}

body[dir="ltr"] .Aside-module__menuSection > ul > li:hover::before,
body[dir="ltr"] .Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::before {
  left: -20px;
}

.Aside-module__menuSection > ul > li:hover::after,
.Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::after {
  content: "";
  position: absolute;
  background-color: #f4f7fb;
  width: 20px;
  height: 100%;

  top: 0;
}

body[dir="rtl"] .Aside-module__menuSection > ul > li:hover::after,
body[dir="rtl"] .Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::after {
  left: -20px;
}

body[dir="ltr"] .Aside-module__menuSection > ul > li:hover::after,
body[dir="ltr"] .Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::after {
  right: -20px;
}

body[dir="rtl"] .Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::before {
  border-right: solid 3px var(--blueColor);
}

body[dir="ltr"] .Aside-module__menuSection > ul > li.Aside-module__activeMenuLink::before {
  border-left: solid 3px var(--blueColor);
}

.Aside-module__menuSection > ul > li > a {
  display: flex;
  align-items: center;
  color: var(--lightGray);
  font-size: 14px;
}

.Aside-module__menuSection > ul > li > a > span {
  margin: 8px 10px;
}

.Aside-module__menuSection > ul > li > a > img {
  width: 16px !important;
}

body[dir="ltr"] .Aside-module__menuSection > ul > li > a > img[src*="Call Us@"] {
  transform: rotateY(180deg);
}

.Aside-module__menuSection > ul > li > a > img[src*="Blue"],
.Aside-module__menuSection > ul > li:hover > a > img[src*="Blue"] + img,
.Aside-module__menuSection > ul > li.Aside-module__activeMenuLink > a > img[src*="Blue"] + img {
  display: none;
}

.Aside-module__menuSection > ul > li:hover > a > img[src*="Blue"],
.Aside-module__menuSection > ul > li.Aside-module__activeMenuLink > a > img[src*="Blue"] {
  display: inline;
}

.Aside-module__menuSection + .Aside-module__menuSection::before {
  content: "";
  height: 1px;
  width: 90%;
  background-color: #cdd1d4;
  position: absolute;
  left: calc(50% - 90% / 2);
  top: 0;
}

.Aside-module__menuFooter {
  padding: 20px;
}

.Aside-module__menuFooter > .Aside-module__greenGrd {
  margin-bottom: 20px;
}

.Aside-module__socialMedia {
  margin-top: 20px;
  color: var(--lightGray);
}

.Aside-module__socialMedia > span.Aside-module__title {
  font-size: 12px;
  margin-bottom: 20px;
  display: block;
}

.Aside-module__socialMedia > ul {
  display: flex;
  align-items: center;
}

.Aside-module__socialMedia > ul > li {
  margin: 0 8px;
}

.Aside-module__menuFooter > .Aside-module__ftrLinks {
  margin-top: 20px;
}

.Aside-module__menuFooter > .Aside-module__ftrLinks > a {
  color: var(--lightGray);
  font-size: 12px;
}

.Aside-module__menu {
  /* transition: 0.5s; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

.Aside-module__menu:not(.Aside-module__showMenu) {
  opacity: 0;
  pointer-events: none;
}

body[dir="rtl"] .Aside-module__menu.Aside-module__showMenu .Aside-module__menuContent {
  left: 0;
}

body[dir="ltr"] .Aside-module__menu.Aside-module__showMenu .Aside-module__menuContent {
  right: 0;
}


.Aside-module__menuSection *::-moz-selection {
  background-color: transparent;
}


.Aside-module__menuSection *::selection,
.Aside-module__menuSection a:active {
  background-color: transparent;
}


.Aside-module__sejelCont .Aside-module__sejelCont2 {
  position: static;
}

.Aside-module__sejelCont .Aside-module__sejelCont2 > .Aside-module__container {
  flex-direction: column;
}

.Aside-module__sejelCont .Aside-module__sejelCont2 > .Aside-module__container > p {
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 15px;
}

.Aside-module__sejelCont .Aside-module__footerWithVat > img {
  max-width: 170px;
}

.Aside-module__sejelCont a.Aside-module__vatLink:after {
  height: 28px;
  width: 1px;
  top: -5px;
  background-color: #a6aeb3;
}

.Aside-module__sejelCont .Aside-module__sejelCont2 {
  display: block !important;
  padding-top: 20px;
  padding-bottom: 20px;
}





.Aside-module__sejelCont2 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #e8f6fe;
  color: #484848;
  padding: 4px 10px;
  z-index: 999;
  box-sizing: border-box;
}

.Aside-module__sejelCont2 > .Aside-module__container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Aside-module__sejelCont2 > .Aside-module__container > img {
  max-width: 200px;
}

.Aside-module__sejelCont2 > .Aside-module__container > p {
  font-size: 14px;
  margin: 0;
}

.Aside-module__sejelCont2 > .Aside-module__container > p > span.Aside-module__icon-greenArmor {
  vertical-align: middle;
  margin: 0 5px;
}


/* vatIcon */
.Aside-module__footerWithVat {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 265px;
}

.Aside-module__footerWithVat > img {
  max-width: 200px;
  width: 100%;
}

.Aside-module__footerWithVat > .Aside-module__vatLink {
}

a.Aside-module__vatLink:after {
  content: "";
  width: 2px;
  height: 32px;
  background-color: #b2bcc2;
  display: inline-block;
  margin: 0 15px;
  position: relative;
  top: -2px;
}

